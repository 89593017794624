import React, { useState } from 'react'
import { Link } from "react-router-dom"
import logo from '../images/logo.png'
import SocialMediaShare from './SocialMediaShare'
import { commonInfo } from '../components/Data'

export default function Header() {
    const [isOpen, setIsOpen] = useState(false)
    const initMobileMenu = () => {
        if (window.innerWidth > 767) {
            setIsOpen(false)
        }
    }
    window.addEventListener("resize", initMobileMenu)
    return (

        <header className='z-[9999] sticky left-0 top-0'>
            <nav className=' w-full bg-[#fff] shadow-[0_4px_2px_-2px_rgba(149,157,165,0.2)] h-16 md:h-auto'>
                <div className='section-container'>
                    <div className='flex justify-between items-center'>
                        <div className='flex items-center'>
                            <Link to="/">
                                <div className='-pb-2 flex'>
                                    <h1 className='text-xl md:text-2xl text-theme-color-1 my-auto mr-2'></h1>

                                    <img className='w-16' src={logo} alt="logo"></img>
                                </div>
                            </Link>

                            <div className='ml-[30px] lg:ml-[50px] hidden md:block'>
                                <ul className='flex '>
                                    {/* <li className='relative group'>
                                            <a href="#" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent font-bold hover:border-blue'>服務簡介</a>
                                            <ul className='py-[10px] pt-0 min-w-[160px] max-w-[240px] scale-0 transition-all ease-linear origin-top-left absolute top-full -right-full z-10 group-hover:scale-100'>
                                                <li>
                                                    <a href="#" className='bg-white inline-block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent hover:border-blue'>服務簡介</a>
                                                </li>
                                                <li>
                                                    <a href="#" className='bg-white inline-block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent hover:border-blue'>服務簡介</a>
                                                </li>
                                                <li>
                                                    <a href="#" className='bg-white inline-block px-3 py-[15px] text-[rgba(0,0,0,.6)] hover:text-[rgba(0,0,0,1)] border-b-[2px] border-transparent hover:border-blue'>服務簡介</a>
                                                </li>
                                            </ul>
                                        </li> */}
                                    <li>
                                        <a href="/" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent text-xl font-bold hover:border-blue'>主頁</a>
                                    </li>
                                    <li>
                                        <a href="/關於我們" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent text-lg font-bold hover:border-blue'>關於我們</a>
                                    </li>

                                    <li>
                                        <a href="/#question" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent text-lg font-bold hover:border-blue'>常見問題</a>
                                    </li>
                                    <li>
                                        <a href="/#case" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent text-lg font-bold hover:border-blue'>個案分享</a>
                                    </li>
                                    <li>
                                        <a href="/文章列表" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent text-lg font-bold hover:border-blue'>文章列表</a>
                                    </li>

                                    <li>
                                        <a href="/#contact-us" className='inline-block px-3 py-[20px] lg:py-[25px] text-black opacity-60 hover:opacity-100 border-b-[2px] border-transparent text-lg font-bold hover:border-blue'>聯絡我們</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className=' hidden md:block'>
                            <div className='flex'>
                                <SocialMediaShare />
                                <div className='grid'>
                                    <span className='text-sm my-auto'>
                                        Whatsapp <a href={`https://api.whatsapp.com/send/?phone=${commonInfo.phone}&text&type=phone_number&app_absent=0`}>{commonInfo.phone}</a>

                                    </span>

                                </div>

                            </div>

                        </div>

                        <div className='flex items-center'>
                            <div className='block cursor-pointer ml-[20px] md:hidden' onClick={() => setIsOpen(!isOpen)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${isOpen ? "hidden" : "block"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${isOpen ? "block" : "hidden"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>


                        </div>
                    </div>
                </div>
            </nav>
            <div className={`fixed top-16 w-full bg-white ${isOpen ? "block h-full" : "hidden"}`}>
                <div className=''>


                    <ul className='mt-[20px]'>
                        {/*<li className='relative'>
                                <a href="" className='inline-block px-3 py-[7px] pl-[20px] text-black w-[85%] opacity-60 mr-[50px] hover:opacity-100'>服務簡介</a>
                                <div className='cursor-pointer absolute top-0 right-0 mt-[2px] mr-[10px] w-[35px] h-[35px] text-center opacity-60 flex justify-center items-center hover:opacity-100' onClick={() => setMobileCollapse(!mobileCollapse)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${mobileCollapse ? "hidden":"block"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-5 w-5 ${mobileCollapse ? "block":"hidden"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
                                    </svg>
                                </div>
                                <ul className={`ml-[30px] overflow-hidden transition-all ${mobileCollapse ? "max-h-[1000px]":"max-h-0"}`}>
                                    <li>
                                        <a href="" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>主頁</a>
                                    </li>
                                    <li>
                                        <a href="" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>主頁</a>
                                    </li>
                                    <li>
                                        <a href="" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>主頁</a>
                                    </li>
                                    <li>
                                        <a href="" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>主頁</a>
                                    </li>
                                </ul>
                            </li>*/}
                        <li>
                            <a target="_blank" rel="noreferrer" href="/關於我們" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>關於我們</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="/文章列表" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>文章列表</a>
                        </li>

                        <li>
                            <a target="_blank" rel="noreferrer" href="/#question" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>常見問題</a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="/#case" className='inline-block px-3 py-[7px] pl-[20px] w-full text-black opacity-60 hover:opacity-100'>個案分享</a>
                        </li>

                    </ul>
                    <hr className='w-full border-[#e1e1e1]' />
                    <a href={`https://api.whatsapp.com/send/?phone=${commonInfo.phone}&text&type=phone_number&app_absent=0`} className='bg-[#25d366] text-[#fff] rounded-[3px] px-[20px] py-[12px] border-[1.5px] border-[#25d366] font-bold block mx-[20px] mt-[15px] text-center'>聯絡我們 Whatsapp {commonInfo.phone} </a>
                </div>
            </div>
        </header>



    )
}
