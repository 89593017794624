import React, { useState } from 'react'
import './Slider.css'
/* import BtnSlider from './BtnSlider' */
import { v4 as uuidv4 } from "uuid";
import leftArrow from "./icons/left-arrow.svg";
import rightArrow from "./icons/right-arrow.svg";
/* import dataSlider from './dataSlider' */
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Slider() {
    const dataSlider = [
        {
            id: uuidv4(),
            title: "保証批核",
            subTitle: "絕不收取任何手續費"
        }
    ];


    const [slideIndex, setSlideIndex] = useState(1)

    const nextSlide = () => {
        if (slideIndex !== dataSlider.length) {
            setSlideIndex(slideIndex + 1)
        }
        else if (slideIndex === dataSlider.length) {
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1) {
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    return (
        <div className="container-slider">
            {dataSlider.map((obj, index) => {
                return (
                    <div
                        key={obj.id}
                        className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <LazyLoadImage
                            src={require(`./images/index-cover${index + 1}.webp`)}
                            alt="Cashing Credit Capital清數"
                        />
                    </div>
                )
            })}
            <button
                onClick={nextSlide}
                className="btn-slide next"
            >
                <img src={rightArrow} alt="Cashing Credit Capital清數"/>
            </button>
            <button
                onClick={prevSlide}
                className="btn-slide prev"
            >
                <img src={leftArrow} alt="Cashing Credit Capital清數"/>
            </button>
            {/* <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/> */}

            <div className="container-dots">
                {dataSlider.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => moveDot(index + 1)}
                        className={slideIndex === index + 1 ? "dot active" : "dot"}
                    >

                    </div>
                ))}
            </div>
            {dataSlider.map((obj, index) => {
                return (
                    <div
                        key={obj.id}
                        className={slideIndex === index + 1 ? "txt-container active abosulte top-24 md:top-36 w-full " : "txt-container "}
                    >
                        <h2 className='text-white text-6xl md:text-7xl text-left ml-12'>{obj.title}</h2>
                        <div className='mt-2 md:mt-8 text-4xl  text-left ml-4 md:ml-12 '>
                            <span className='text-white md:text-6xl bg-black'>{obj.subTitle}</span>
                        </div>


                    </div>
                )
            })}
        </div>
    )
}
