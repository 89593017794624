import React from 'react'
import Slider from '../components/Carousel/Slider'

import SocialMediaShare from '../components/SocialMediaShare'
import Swiper from '../components/Swiper'
import { DigitalMarketingSwiperList, MetaDataList } from '../components/Data'
import MetaDecorator from '../components/MetaDecorator'
import boatJPG from '../images/boat.jpg'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ChatAlt2Icon, ClipboardCheckIcon, CubeTransparentIcon, ShieldCheckIcon, CreditCardIcon, CurrencyDollarIcon } from '@heroicons/react/outline'
import {
    FacebookIcon,
} from "react-share";
import logo from '../images/logo.png'

export default function Boat() {
    return (
        <>
            <MetaDecorator title={MetaDataList.home.title} description={MetaDataList.home.description} keyword={MetaDataList.home.keyword} page={MetaDataList.home.page} breadcrumb={MetaDataList.home.breadcrumb} main_description={MetaDataList.home.description} />

            <section id="about-us" className="bg-white bg-no-repeat bg-cover bg-scroll bg-[50%_0] py-[70px]">
                <h1 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-1 underline-offset-8 underline'>船貸服務</h1>
                <div className='section-container mx-auto px-[30px]'>
                    <div className=' flex-col-reverse md:flex-row flex items-center justify-between'>
                        <div className='flex-1 text-center md:text-left'>
                            <h2 className='text-[40px] mb-[20px] wow fadeInLeft'><span className='font-bold text-theme-color-4 '>船隻貸款</span> 打破市場船期規則</h2>
                            <div className='wow fadeInLeft'>
                                <p className='text-2xl'>* 按揭成數可達估價 50%</p>
                                <p className='text-2xl'>* 利息低至 14%</p>
                                <p className='text-2xl'>* 期數最長72期</p>
                                <p className='text-2xl'>* 船齡不限</p>
                                <p className='text-2xl'>* 優惠價泊位及看管服務</p>
                                <p className='text-2xl'>* 一站式服務 最快一日批核</p>
                            </div>
                            <div className='wow fadeInUp'>
                                <a href="#contact-us" className='effect-cta text-theme-color-5 font-bold text-xl'>聯絡我們</a>
                            </div>
                        </div>
                        <div className='flex-1 md:ml-[30px] mb-[30px] md:mb-0 wow zoomIn'>
                            <LazyLoadImage
                                className='mx-auto'
                                src={boatJPG}
                                alt="Cashing Credit Capital"
                            />                        </div>
                    </div>
                </div>
            </section>
            <p className='text-center text-4xl text-theme-color-5 font-bold italic'>借錢梗要還，咪俾錢中介</p>

            <section id="contact-us" className='py-[70px] scroll-mt-[90px]'>
                <h2 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-1 underline-offset-8 underline'>聯絡我們</h2>
                <div className='section-container'>
                    <div className='flex justify-center flex-col md:flex-row'>
                        <div className='md:w-[45%]  flex mb-[50px] px-4 md:px-0 md:mb-0 flex-col wow fadeInLeft'>
                            <div className='mb-[40px] w-full'>
                                <p className='text-xl'>無論您是小型企業還是大型企業， Cashing Credit Capital都能為您提供全面的清數解決方案，確保您的財務數據的準確性和可靠性，讓您專注於業務發展。</p>
                                <p className='text-xl'>如有任何形式的資產，如物業，商鋪，車位，工廈，股票，債券，名牌手錶，可免入息証明而保証批核，詳情可與我們客戶服務主任查詢。</p>
                                <div className='break-words w-full'>
                                    <div className="block md:flex">
                                        <span className='text-xl my-auto'>歡迎直接聯絡我們！</span>
                                        <div className='flex'>
                                            <SocialMediaShare />
                                            <div className='block'>
                                            <span>Facebook</span>
                                            <a href='https://www.facebook.com/Cashing Credit Capitalloan' target="_blank" rel="noreferrer">
                                                <FacebookIcon className="mx-1" size='48' round={true} />
                                            </a>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='md:w-[45%] max-w-[650px] wow fadeInRight'>

                        </div>
                    </div>
                </div>
            </section>


        </>

    )
}
