import React from 'react'
import Slider from '../components/Carousel/Slider'

import SocialMediaShare from '../components/SocialMediaShare'
import Swiper from '../components/Swiper'
import { dataArticleList, MetaDataList } from '../components/Data'
import MetaDecorator from '../components/MetaDecorator'
import article1 from '../images/case1.webp'
import index2 from '../images/index2.webp'
import index3 from '../images/index3.webp'
import index4 from '../images/index4.webp'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ChatAlt2Icon, ClipboardCheckIcon, CubeTransparentIcon, ShieldCheckIcon, CreditCardIcon, CurrencyDollarIcon } from '@heroicons/react/outline'
import {
    FacebookIcon,
} from "react-share";
import logo from '../images/logo.png'

export default function ArticleList() {
    return (
        <div>
            <MetaDecorator title={MetaDataList.home.title} description={MetaDataList.home.description} keyword={MetaDataList.home.keyword} page={MetaDataList.home.page} breadcrumb={MetaDataList.home.breadcrumb} main_description={MetaDataList.home.description} />


            <section id="promise" className='bg-white py-[70px] '>
                <div className='section-container max-w-[1300px] '>
                    <h2 className='mb-[40px] text-center text-4xl wow fadeIn text-theme-color-1 underline-offset-8 underline'>文章列表</h2>
                    <div className='mt-[50px] grid md:grid-cols-3 gap-[40px] wow bounceIn'>
                        <div className='h-full rounded-[15px] bg-theme-color-5'>
                            <a href='借貸與財務自由：探索有效的財務管理策略'>
                                <div className='w-full'>
                                    <img src={article1} />
                                </div>
                                <h3 className='text-theme-color-4 text-2xl p-4 mb-[30px]'>「借貸與財務自由：探索有效的財務管理策略」</h3>
                            </a>
                        </div>
                        <div className='h-full rounded-[15px] bg-theme-color-5'>
                            <a href='借貸與金融風險：如何避免陷入債務困境'>
                                <div className='w-full'>
                                    <img src={article1} />
                                </div>
                                <h3 className='text-theme-color-4 text-2xl p-4 mb-[30px]'>「借貸與金融風險：如何避免陷入債務困境」</h3>
                            </a>
                        </div>
                        <div className='h-full rounded-[15px] bg-theme-color-5'>
                        <a href='借貸與創業：資金籌措的關鍵考量'>
                            <div className='w-full'>
                                <img src={article1} />
                            </div>
                            <h3 className='text-theme-color-4 text-2xl p-4 mb-[30px]'>「借貸與創業：資金籌措的關鍵考量」</h3>
                            </a>
                        </div>
                    </div>
                    <div className='mt-[50px] grid md:grid-cols-3 gap-[40px] wow bounceIn'>
                        <div className='h-full rounded-[15px] bg-theme-color-5'>
                        <a href='借貸與教育：學生貸款的利與弊'>
                            <div className='w-full'>
                                <img src={article1} />
                            </div>
                            <h3 className='text-theme-color-4 text-2xl p-4 mb-[30px]'>「借貸與教育：學生貸款的利與弊」</h3>
                            </a>
                        </div>
                        <div className='h-full rounded-[15px] bg-theme-color-5'>
                        <a href='借貸與消費：欠款與理性金錢使用之間的平衡'>
                            <div className='w-full'>
                                <img src={article1} />
                            </div>
                            <h3 className='text-theme-color-4 text-2xl p-4 mb-[30px]'>「借貸與消費：欠款與理性金錢使用之間的平衡」</h3>
                            </a>
                        </div>

                    </div>
                </div>
            </section>
            <section id="contact-us" className='py-[70px] scroll-mt-[90px]'>
                <h2 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-1 underline-offset-8 underline'>聯絡我們</h2>
                <div className='section-container'>
                    <div className='flex justify-center flex-col md:flex-row'>
                        <div className='md:w-[45%]  flex mb-[50px] px-4 md:px-0 md:mb-0 flex-col wow fadeInLeft'>
                            <div className='mb-[40px] w-full'>
                                <p className='text-xl'>無論您是小型企業還是大型企業， Cashing Credit Capital都能為您提供全面的清數解決方案，確保您的財務數據的準確性和可靠性，讓您專注於業務發展。</p>
                                <p className='text-xl'>如有任何形式的資產，如物業，商鋪，車位，工廈，股票，債券，名牌手錶，可免入息証明而保証批核，詳情可與我們客戶服務主任查詢。</p>
                                <div className='break-words w-full'>
                                    <div className="flex">
                                        <span className='text-xl my-auto'>歡迎直接聯絡我們！</span>
                                        <SocialMediaShare />
                                        <a href='https://www.facebook.com/profile.php?id=61551910717885&mibextid=ZbWKwL' target="_blank" rel="noreferrer">
                                            <FacebookIcon className="mx-1" size='48' round={true} />
                                        </a>                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='md:w-[45%] max-w-[650px] wow fadeInRight'>

                        </div>
                    </div>
                </div>
            </section>


        </div>

    )
}
