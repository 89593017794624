import React from 'react'
import Slider from '../components/Carousel/Slider'
import SocialMediaShare from '../components/SocialMediaShare'
import Swiper from '../components/Swiper'
import { DigitalMarketingSwiperList, MetaDataList } from '../components/Data'
import MetaDecorator from '../components/MetaDecorator'
import index1 from '../images/index1.webp'
import index2 from '../images/index2.webp'
import index3 from '../images/index3.webp'
import index4 from '../images/index4.webp'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AcademicCapIcon } from '@heroicons/react/24/outline'
import {
    FacebookIcon,
} from "react-share";
import logo from '../images/logo.png'

export default function Home() {
    return (
        <>
            <MetaDecorator title={MetaDataList.home.title} description={MetaDataList.home.description} keyword={MetaDataList.home.keyword} page={MetaDataList.home.page} breadcrumb={MetaDataList.home.breadcrumb} main_description={MetaDataList.home.description} />
            <Slider />
            <section id="about-us" className="bg-white bg-no-repeat bg-cover bg-scroll bg-[50%_0] py-[70px]">
                <h2 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-1 underline-offset-8 underline'>關於我們</h2>
                <div className='section-container mx-auto px-[30px]'>
                    <div className=' flex-col-reverse md:flex-row flex items-center justify-between'>
                        <div className='flex-1 text-center md:text-left'>
                            <h2 className='text-[40px] mb-[20px] wow fadeInLeft'><span className='font-bold text-theme-color-4 '>專業</span> 清數</h2>
                            <div className='wow fadeInLeft'>
                                <p className='text-2xl'>成立於2012年是一間擁有超過10年以上經驗的專業貸款團隊，職員全部曾任職銀行及財務行業。</p>
                                <p className='text-2xl'>過往成功處理數千宗減輕供款個案，幫助不同行業，不同財務問題的客人順利減輕供款壓力，減少客人多次申請，多次遞交文件仍然不成功的申請問題。</p>
                                <br />
                                <p className='text-2xl'>我們與多間銀行，財務機構有多年合作關係，保証一定令你可以成功貸款！</p>
                            </div>
                            <div className='wow fadeInUp'>
                                <a href="#contact-us" className='effect-cta text-theme-color-5 font-bold text-xl'>聯絡我們</a>
                            </div>
                        </div>
                        <div className='flex-1 md:ml-[30px] mb-[30px] md:mb-0 wow zoomIn'>
                            <LazyLoadImage
                                className='mx-auto'
                                src={index1}
                                alt="Cashing Credit Capital"
                            />                        </div>
                    </div>
                </div>
            </section>
            <p className='text-center text-4xl text-theme-color-5 font-bold italic'>借錢梗要還，咪俾錢中介</p>
            <section className="bg-theme-color-5">
                <div className='section-container mx-auto pb-8'>
                    <div className=' flex-col md:flex-row flex items-center justify-between'>
                        <div className='flex-1 md:ml-[30px] flex-col mb-[30px] md:mb-0 wow rotateIn'>
                            <LazyLoadImage
                                className='mx-auto'
                                src={index2}
                                alt="Cashing Credit Capital"
                            />                        </div>
                        <div className='flex-1 text-white text-center md:text-left'>
                            <h3 className='text-[40px] mb-[20px] wow fadeInUp'><span className='font-bold text-theme-color-4 '>服務涵蓋</span> 多個方面</h3>
                            <div className='wow fadeInUp'>
                                <p className='text-2xl'>服務範圍包括：各種借貸分析，財務評估，專業清數計畫，結餘轉戶，物業貸款，資產抵押，債務舒緩，破產管理，免入息貸款，保單融資，數據分析，TU信貸整理，樓宇買賣等</p>
                            </div>
                            <div className='wow bounceInUp'>
                                <a href="#contact-us" className='effect-cta text-theme-color-5 font-bold text-xl'>聯絡我們</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section id="promise" className='bg-white py-[70px] '>
                <div className='section-container max-w-[1300px] mx-auto'>
                    <h2 className='mb-[40px] text-center text-4xl wow fadeIn text-theme-color-1 underline-offset-8 underline'>三大承諾</h2>
                    <div className='mt-[50px] grid md:grid-cols-3 gap-[40px] wow bounceIn'>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-white flex justify-center items-center'>
                                <AcademicCapIcon className='w-12' />
                            </div>
                            <h3 className='text-theme-color-4 text-3xl  mb-[30px]'>保証0手續費</h3>
                            <p className='text-white text-2xl leading-7'>為免陷入中介公司要另外收取手續費，已經壓力山大還要比錢中介公司，我們保証不收取客人任何形式的手續費！我們主要收入是跟不同友好銀行，財務機構令你真真正正一次性處理財務上壓力，這是我們的服務宗旨。</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='w-[75px] h-[75px]  mb-[45px] rounded-[15px] bg-white flex justify-center items-center'>
                                <AcademicCapIcon className='w-12' />
                            </div>
                            <h3 className='text-theme-color-4 text-3xl  mb-[30px]'>保証成功申請</h3>
                            <p className='text-white text-2xl leading-7'>香港人每日都會接到不同嘅廣告電話叫你申請貸款，但事實上他是否真的任職那間公司你無從得知，亦很多時只會叫你不斷申請不同公司但仍然話批核唔到，文件不足，評分不足等不同理由reject 你！既浪費時間亦令信貸評分越黎越差！</p>
                            <p className='text-white text-2xl'>我們專業團隊會先跟客人溝通好，以我們多年經驗及實力能令你最短時間內達到成功申請，最快可以1個小時内完成批核！</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-white flex justify-center items-center'>
                                <AcademicCapIcon className='w-12' />
                            </div>
                            <h3 className='text-theme-color-4 text-3xl  mb-[30px]'>貸款無上限</h3>
                            <p className='text-white text-2xl leading-7'>無論你從事任何職業，自僱定受僱，有樓定無樓，是否曾破產，走數，遲還款，或做過DRP, IVA等，我們都會度身訂造一個專業嘅貸款方案比你，令你可以輕鬆地借到一筆你想要嘅現金，無需逐間比較，令你順利提昇信貸評級，提昇信用卡額度，靈活周轉。</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="service" className="bg-white bg-no-repeat bg-cover bg-scroll bg-[50%_0] py-[70px]">
                <h2 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-1 underline-offset-8 underline'>服務簡介</h2>
                <div className='section-container mx-auto px-[30px]'>
                    <div className=' flex-col-reverse md:flex-row flex items-center justify-between'>
                        <div className='flex-1 text-center md:text-left'>
                            <h2 className='text-[40px] mb-[20px] wow fadeInLeft'><span className='font-bold text-theme-color-4 '>大額</span>貸款</h2>
                            <div className='wow fadeInLeft'>
                                <p className='text-2xl'>無論您是個人或企業，當您需要籌集大量資金來實現重要計劃時，大額貸款可以是一個理想的選擇。透</p>
                                <p className='text-2xl'>過大額貸款，您可以獲得足夠的資金，並且根據您的還款能力制定彈性的還款計劃。請注意，申請大額貸款可能需要提供相應的擔保或抵押品，以確保貸款的安全性。</p>
                            </div>

                            <div className='wow fadeInUp'>
                                <a href="#contact-us" className='effect-cta text-theme-color-5 font-bold text-xl'>聯絡我們</a>
                            </div>
                        </div>
                        <div className='flex-1 md:ml-[30px] mb-[30px] md:mb-0 wow zoomIn'>
                            <LazyLoadImage
                                className='mx-auto'
                                src={index3}
                                alt="Cashing Credit Capital"
                            />                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-theme-color-5">
                <div className='section-container mx-auto pb-8'>
                    <div className=' flex-col md:flex-row flex items-center justify-between'>
                        <div className='flex-1 md:ml-[30px] flex-col mb-[30px] md:mb-0 wow rotateIn'>
                            <LazyLoadImage
                                className='mx-auto'
                                src={index4}
                                alt="Cashing Credit Capital"
                            />
                        </div>
                        <div className='flex-1 text-white text-center md:text-left'>
                            <h3 className='text-[40px] mb-[20px] wow fadeInUp'><span className='font-bold text-theme-color-4 '>物業</span>貨款</h3>
                            <div className='wow fadeInUp'>
                                <p className='text-2xl'>物業貨款通常由銀行或其他金融機構提供，其特點是貸款金額相對較高，還款期限較長，並且利率通常是固定的或可變的。</p>
                                <p className='text-2xl'>此外，貸款金額可能會受到您的信用狀況、所選房屋價值以及其他相關因素的影響。</p>
                            </div>
                            <div className='wow bounceInUp'>
                                <a href="#contact-us" className='effect-cta text-theme-color-5 font-bold text-xl'>聯絡我們</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section id="question" className='bg-white py-[70px] '>
                <div className='section-container max-w-[1300px] mx-auto'>
                    <h2 className='mb-[40px] text-center text-4xl wow fadeIn text-theme-color-1 underline-offset-8 underline'>常見問題</h2>
                    <div className='mt-[50px] grid md:grid-cols-3 gap-[40px] wow bounceIn'>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-white flex justify-center items-center'>
                                <AcademicCapIcon className='w-12' />
                            </div>
                            <h3 className='text-theme-color-4 text-3xl mb-[30px]'>什麼是清數服務？</h3>
                            <p className='text-white text-2xl leading-7'>清數貸款指將你分散在不同貸款機構包括不同信用卡的卡數、私人貸款等的債務（俗稱「街數」），透過向銀行或財務公司申請一筆利息相對較低的私人貸款，一次過集中處理，清卡數貸款又稱結餘轉戶貸款。</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-white flex justify-center items-center'>
                                <AcademicCapIcon className='w-12' />
                            </div>
                            <h3 className='text-theme-color-4 text-3xl mb-[30px]'>為什麼我們需要清數服務？</h3>
                            <p className='text-white text-2xl leading-7'>可能同時欠下多筆未清卡數及私人貸款，所以清數計畫能將債務化整為零再一筆清，將多項外債集中成一項債務方便清數。加上清數實際年利率較低，這個一筆清方法能節省高利息支出。當然，令你信貸評分早日upgrade!</p>
                        </div>
                        <div className='p-[40px] h-full rounded-[15px] bg-theme-color-5'>
                            <div className='w-[75px] h-[75px] mb-[45px] rounded-[15px] bg-white flex justify-center items-center'>
                                <AcademicCapIcon className='w-12' />
                            </div>
                            <h3 className='text-theme-color-4 text-3xl mb-[30px]'>清數過程需要多長時間？</h3>
                            <p className='text-white text-2xl leading-7'>取決於case的複雜性，我們承諾盡量1天內就可以給你一個初步計畫！令客人免去煩瑣嘅手續及比較申請。</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="case" className='py-[70px]'>
                <h2 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-1 underline-offset-8 underline'>個案分享</h2>
                <Swiper arrayList={DigitalMarketingSwiperList} />
            </section>
            <section id="contact-us" className='py-[70px] scroll-mt-[90px]'>
                <h2 className='mb-[40px] text-center wow text-4xl fadeIn text-theme-color-1 underline-offset-8 underline'>聯絡我們</h2>
                <div className='section-container'>
                    <div className='flex justify-center flex-col md:flex-row'>
                        <div className='md:w-[45%]  flex mb-[50px] px-4 md:px-0 md:mb-0 flex-col wow fadeInLeft'>
                            <div className='mb-[40px] w-full'>
                                <p className='text-xl'>無論您是小型企業還是大型企業，Cashing Credit Capital都能為您提供全面的清數解決方案，確保您的財務數據的準確性和可靠性，讓您專注於業務發展。</p>
                                <p className='text-xl'>如有任何形式的資產，如物業，商鋪，車位，工廈，股票，債券，名牌手錶，可免入息証明而保証批核，詳情可與我們客戶服務主任查詢。</p>
                                <div className='break-words w-full'>
                                    <div className="block md:flex">
                                        <span className='text-xl my-auto'>歡迎直接聯絡我們！</span>
                                        <div className='flex'>
                                            <SocialMediaShare />
                                            <div className='block'>
                                                <span></span>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="w-full">
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>


        </>

    )
}
