import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from "react-router";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import Boat from "./pages/Boat";
import Car from "./pages/Car";
import AboutUs from "./pages/AboutUs";
import Article1 from "./pages/Article1";
import Article2 from "./pages/Article2";
import Article3 from "./pages/Article3";
import Article4 from "./pages/Article4";
import Article5 from "./pages/Article5";

import ArticleList from "./pages/ArticleList";
function App() {


  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/關於我們" element={<AboutUs />} />
        <Route path="/船貸服務" element={<Boat />} />
        <Route path="/車貸服務" element={<Car />} />
        <Route path="/文章列表" element={<ArticleList />} />
        <Route path="/借貸與財務自由：探索有效的財務管理策略" element={<Article1 />} />
        <Route path="/借貸與金融風險：如何避免陷入債務困境" element={<Article2 />} />
        <Route path="/借貸與創業：資金籌措的關鍵考量" element={<Article3 />} />
        <Route path="/借貸與教育：學生貸款的利與弊" element={<Article4 />} />
        <Route path="/借貸與消費：欠款與理性金錢使用之間的平衡" element={<Article5 />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
